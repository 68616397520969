<template>
  <ZTable size="sm">
    <!-- Rental Amount -->
    <ZTableLineItem
      :label="t('booking.rentalXnights', financialTotalNights)"
      :value="formatPrice({ value: financialTotalPreDiscountRentalAmount })"
      value-data-testid="rental-nights-cost"
    />

    <!-- Discounts -->
    <ZTableLineItem
      v-for="discount in discounts"
      :key="discount.type"
      :label="discount.label"
      :value="formatPrice({ value: discount.amount })"
      :value-data-testid="`${discount.type}-discount-amount`"
      discount
    />

    <!-- Promo Code -->
    <ZTableLineItem
      v-if="financialPromoCode"
      :value="formatPrice({ value: -financialTotalPromotionalDiscountAmount })"
      discount
    >
      <template #label>
        <div class="d-flex">
          {{ t('pages.bookingPayment.promoCode.label') }}

          <span class="ml-auto">
            <ZButton
              link
              data-testid="remove-promo-code-button"
              class="p-0 mr-1"
              size="sm"
              @click="removePromocode"
            >
              <Fa :icon="['far', 'xmark']" />
            </ZButton>
            {{ financialPromoCode }}
          </span>
        </div>
      </template>
    </ZTableLineItem>

    <!-- Mileage -->
    <ZTableLineItem
      v-if="rvIsMotorized"
      :label="rvSnapshotIsChargeMileageWithSomeIncluded
        ? t(`booking.mileage.${rvCountry}.text`, {
          dailyKmAllowed: rvSnapshotDailyKMAllowed,
        })
        : t('booking.unlimitedMileage')"
      :value="t('included')"
      :tooltip="rvSnapshotIsChargeMileageWithSomeIncluded ? t(`booking.mileage.${rvCountry}.tooltip`, {
        kms: rvSnapshotDailyKMAllowed,
        cost: formatPrice({ value: rvSnapshotSurchargePerExtraKM }),
      }) : undefined"
    />

    <!-- Power Generator -->
    <template v-if="rvSnapshotIsChargeGenerator">
      <!-- Power Generator: Some free hours per day -->
      <ZTableLineItem
        v-if="rvSnapshotHasFreeHoursPerDay"
        :label="t('booking.powerGenerator.chargeText', {
          time: rvSnapshotFreeHoursPerDay,
        })"
        :tooltip="t('booking.powerGenerator.tooltip', {
          time: rvSnapshotFreeHoursPerDay,
          cost: formatPrice({ value: rvSnapshotChargePerHourOver }),
        })"
        :value="t('included')"
      />
      <!-- Power Generator: Zero free hours per day -->
      <ZTableLineItem
        v-else-if="rvSnapshotIsChargeGeneratorEnabled"
        :label="t('booking.powerGenerator.chargeTextNoFreeHours', {
          cost: rvSnapshotChargePerHourOver,
        })"
        :tooltip="t('booking.powerGenerator.tooltipNoFreeHours', {
          cost: rvSnapshotChargePerHourOver,
        })"
      />
      <!-- Power Generator: Unlimted usage -->
      <ZTableLineItem
        v-else
        :label="t('booking.powerGenerator.text')"
        :value="t('included')"
      />
    </template>

    <template v-if="showInsuranceValue">
      <!-- Protection -->
      <ZTableLineItem
        v-if="rvSnapshotIsInsured"
        :label="t(
          `booking.protection.protections.${financialInsuranceProtectionLevel}.protection`,
        )"
        :value="formatPrice({ value: financialTotalProtection })"
      />
      <!-- Roadside -->
      <ZTableLineItem
        v-else-if="financialTotalRoadsides"
        :label="t('booking.roadsideAssistance.text')"
        :value="formatPrice({ value: financialTotalRoadsides })"
      />
    </template>

    <!-- Delivery -->
    <ZTableLineItem
      v-if="financialTotalDelivery"
      :label="t('booking.delivery')"
      :value="formatPrice({ value: financialTotalDelivery })"
      value-data-testid="total-delivery-cost"
    />

    <!-- Addons -->
    <ZTableLineItem
      v-if="financialTotalAddOns"
      label-data-testid="show-payment-details-addons"
      :value="formatPrice({ value: financialTotalAddOns })"
      value-data-testid="total-addons-amount"
      is-clickable
      @click="showAddons = !showAddons"
    >
      <template #label>
        {{ t('booking.addons') }}
        <ZToggleArrow
          :toggled="showAddons"
          class="text-highlight"
        />
      </template>
    </ZTableLineItem>

    <template v-if="showAddons">
      <ZTableLineItem
        v-for="(addon) in addonsList"
        :key="addon.IdInRV"
        :label="getAddonLabel({ slug: addon.Slug ?? '', name: addon.Name ?? '' })"
        :value="addon.TotalAmount ? formatPrice({ value: addon.TotalAmount }) : t('included')"
        :nested="1"
      />
    </template>

    <!-- Taxes and Fees -->
    <ZTableLineItem
      :value="formatPrice({ value: totalTaxesAndFees })"
      is-clickable
      @click="showTaxesAndFees = !showTaxesAndFees"
    >
      <template #label>
        {{ t('booking.taxesAndFees') }}
        <ZToggleArrow
          :toggled="showTaxesAndFees"
          class="text-highlight"
        />
      </template>
    </ZTableLineItem>

    <template v-if="showTaxesAndFees">
      <!-- Taxes and Fees: Rvezy Fee -->
      <ZTableLineItem
        :label="t('booking.RVezyFee.text')"
        :tooltip="t('booking.RVezyFee.tooltip')"
        :value="formatPrice({ value: financialTotalRenterServiceFee })"
        :nested="1"
      />

      <!-- Taxes and Fees: Cleaning Fee -->
      <ZTableLineItem
        v-if="financialTotalCleanings"
        :label="t('booking.cleaningFee.text')"
        :tooltip="t('booking.cleaningFee.tooltip')"
        :value="formatPrice({ value: financialTotalCleanings })"
        :nested="1"
      />

      <!-- Taxes and Fees: Security Deposit -->
      <ZTableLineItem
        v-if="!hideSecurityDeposit && financialTotalSecurityDeposits"
        :label="t('booking.securityDeposit.text')"
        :value="formatPrice({ value: financialTotalSecurityDeposits })"
        :nested="1"
      />

      <!-- Taxes and Fees: Owner Sales Taxes -->
      <ZTableLineItem
        v-if="financialTotalOwnerSalesTaxes"
        :label="t('booking.ownerSalesTaxes')"
        :value="formatPrice({ value: financialTotalOwnerSalesTaxes })"
        :nested="1"
      />

      <!-- Taxes and Fees: US Taxes -->
      <ZTableLineItem
        v-if="financialTotalUSTaxes"
        :label="t('booking.salesTax')"
        :value="formatPrice({ value: financialTotalUSTaxes })"
        :nested="1"
      />

      <!-- Taxes and Fees: Renter Taxes -->
      <ZTableLineItem
        v-if="financialTotalRenterTaxes"
        :label="t('booking.tax')"
        :tooltip="t('booking.taxAdvise')"
        :value="formatPrice({ value: financialTotalRenterTaxes })"
        :nested="1"
      />
    </template>
  </ZTable>
</template>

<script setup lang="ts">
import type { FinancialCommon, Nullable, RvSnapshot } from '~/types'

const props = defineProps<{
  financial?: Nullable<FinancialCommon>
  rvSnapshot?: Nullable<RvSnapshot>
  hideSecurityDeposit?: boolean
}>()

const { t } = useI18n()
const { priceDisplay } = usePrice()
const { getAddonLabel } = useAddon()
const { rvCountry, rvIsMotorized } = useRvDetails()

const {
  rvSnapshotChargePerHourOver,
  rvSnapshotDailyKMAllowed,
  rvSnapshotFreeHoursPerDay,
  rvSnapshotIsChargeGenerator,
  rvSnapshotIsChargeMileageWithSomeIncluded,
  rvSnapshotIsInsured,
  rvSnapshotSurchargePerExtraKM,
  rvSnapshotIsChargeGeneratorEnabled,
  rvSnapshotHasFreeHoursPerDay,
} = useRvSnapshot(computed(() => props.rvSnapshot))

const {
  financialAddOns,
  financialBookingId,
  financialDiscountsMidweekdays,
  financialDiscountsMonthly,
  financialDiscountsWeekly,
  financialInsuranceProtectionLevel,
  financialPromoCode,
  financialTotalAddOns,
  financialTotalCleanings,
  financialTotalDelivery,
  financialTotalNights,
  financialTotalOwnerSalesTaxes,
  financialTotalPreDiscountRentalAmount,
  financialTotalPromotionalDiscountAmount,
  financialTotalProtection,
  financialTotalRenterServiceFee,
  financialTotalRenterTaxes,
  financialTotalRoadsides,
  financialTotalSecurityDeposits,
  financialTotalUSTaxes,
} = useFinancialData(computed(() => props.financial))

const showAddons = ref(false)
const showTaxesAndFees = ref(false)

const discounts = computed(() => {
  const discounts = []

  // Midweek discount
  if (financialDiscountsMidweekdays.value) {
    discounts.push({
      type: 'midweek',
      label: t('booking.midweekDiscount', financialDiscountsMidweekdays.value.NumberOfNights),
      amount: toNegativeNumber(financialDiscountsMidweekdays.value.DiscountAmount),
    })
  }

  // Range discount
  if (financialDiscountsMonthly.value) {
    discounts.push({
      type: 'monthly',
      label: t('booking.monthlyDiscount'),
      amount: toNegativeNumber(financialDiscountsMonthly.value.DiscountAmount),
    })
  }
  if (financialDiscountsWeekly.value) {
    discounts.push({
      type: 'weekly',
      label: t('booking.weeklyDiscount'),
      amount: toNegativeNumber(financialDiscountsWeekly.value.DiscountAmount),
    })
  }

  return discounts
})

const hasCustomTotalAddon = computed(() => {
  return financialAddOns.value.some((addon) => addon.AddOnType === 'CustomTotal')
})

const addonsList = computed(() => {
  return financialAddOns.value.filter((addon) => {
    // If there is no custom total addon, we only want to show RVAddOns
    if (!hasCustomTotalAddon.value) {
      return addon.AddOnType === 'RVAddOn'
    }
    // If there is a custom total addon, we only want to show RVAddOns and CleaningFee addons
    return !['Roadside', 'CustomTotal'].includes(addon.AddOnType)
  })
})

const totalTaxesAndFees = computed(() => {
  return (
    financialTotalRenterServiceFee.value
    + financialTotalRenterTaxes.value
    + financialTotalOwnerSalesTaxes.value
    + financialTotalCleanings.value
    + financialTotalUSTaxes.value
  )
})

const showInsuranceValue = computed(() => {
  return financialTotalProtection.value > 0
})

function formatPrice({ value }: { value: number }) {
  return priceDisplay({
    value,
    countryCode: rvCountry.value,
    internationalPricing: true,
  })
}

const { updatePromoCode } = usePromoCode()
async function removePromocode() {
  await updatePromoCode(financialBookingId.value, null)
}
</script>

<i18n src="~/locales/common/booking/mileage.json" lang="json" />

<i18n src="~/locales/common/booking/protection.json" lang="json" />

<i18n src="~/locales/common/booking/rvezy-fee.json" lang="json" />

<i18n src="~/locales/common/booking/security-deposit.json" lang="json" />

<i18n src="~/locales/common/booking/cleaning-fee.json" lang="json" />

<i18n src="~/locales/common/booking/roadside-assistance.json" lang="json" />

<i18n src="~/locales/common/booking/tax.json" lang="json" />

<i18n src="~/locales/common/booking/discount.json" lang="json" />

<i18n src="~/locales/common/booking.json" lang="json" />

<i18n src="~/locales/common/pages/booking-payment.json" lang="json" />
